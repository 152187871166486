import React from "react";
import { Link } from "gatsby";

import imgLB from "../../assets/image/logo-main-black.png";
import imgLW from "../../assets/image/logo-main-white.png";
import imgFS from "../../assets/image/inner-page/png/yellow-footer-shape.png";

const Footer = ({ className, ...rest }) => {
  return (
    <>
      <div
        className={`bg-dark-cloud pt-13 pt-lg-27 pb-7 dark-mode-texts position-relative ${className}`}
        {...rest}
      >
        <div className="container">
          <div className="row pb-lg-25">
            <div className="col-lg-4 col-md-5 col-md-3 col-xs-8">
              <div className="pr-xl-20 mb-11 mb-lg-0 mt-lg-5">
                <p className="font-size-5 mb-0 text-bali-gray pr-sm-10 pr-md-0">
                  Bouwnetwerk is het eerste netwerk in Nederland voor de zelfstandige ondernemer
                </p>
              </div>
            </div>
          </div>
          <div className="row align-items-center pt-10 border-top">
            <div className="col-lg-6 text-center text-lg-left">
              <div className="copyright">
                <p className="mb-0 font-size-3 text-bali-gray">
                  © Bouwnetwerk 2024
                </p>
              </div>
            </div>
            <div className="col-lg-6 text-center text-lg-right">
              <div className="footer-right mt-5 mt-lg-0">
                <ul className="list-unstyled d-flex align-items-center justify-content-center justify-content-lg-end mb-0">
                  <li>
                    <Link
                      to="/#"
                      className="text-white gr-hover-text-blue-3 ml-7"
                    >
                      <i className="fab fa-twitter" />
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/#"
                      className="text-white gr-hover-text-blue-3 ml-7"
                    >
                      <i className="fab fa-facebook-f" />
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-yellow-shape-img">
          <img src={imgFS} alt="" />
        </div>
      </div>
    </>
  );
};

export default Footer;
